@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.page {
    font-family: 'Montserrat', sans-serif;
    background-color: black;
    padding: 1.5em;
    height: fit-content;
}

.top {
    background-color: rgb(49, 41, 41);
    color: white;
    text-align: center;
    padding: 1.5em;
    border-radius: .5em;
    box-shadow: 10px 10px 10px rgba(61, 89, 0, 0.3);
}

.bottom {
    background-color: rgb(49, 41, 41);
    color: white;
    text-align: center;
    padding: 1.5em;
    margin-top: 1.5em;
    border-radius: .5em;
    box-shadow: 10px 10px 10px rgba(61, 89, 0, 0.3);
}

.data {
    text-align: left;
}

.left {
    color: rgb(212, 226, 238);
    font-size: 1.1rem;
}

.right {
    color: rgb(212, 226, 238);
    font-size: 1.1rem;
}

.data p {
    font-size: .95rem;
    color: white;
    padding-top: .5em;
}

h2 {
    font-size: 1.3rem;
}

h3 {
    padding-top: 1em;
}

@media (min-width: 500px) {
    .data {
        display: flex;
        text-align: center;
    }

    .left {
        width: 50%;
    }

    .right {
        width: 50%;
    }
}